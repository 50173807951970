import { motion } from "framer-motion"
import { graphql } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../../components/Layout"
import PageFooter from "../../components/PageFooter"
import PageHeader from "../../components/PageHeader"
import Cards from "../../components/Cards"

function products({ data }) {
  const items = data.allItemsJson.nodes
  const desc = data.productsJson.desc
  const banner = data.productsJson.banner.childImageSharp.fluid

  return (
    <Layout>
      <Helmet>
        <title>
          Produk | Grand Wisata Bekasi | Kota Mandiri Terintegrasi dan Terencana
          di Timur Jakarta
        </title>
        <meta
          name="description"
          content="Grand Wisata hadir dengan menawarkan berbagai produk hunian/ruang usaha yang inovatif dan mengusung konsep lingkungan sehat, memberikan rasa nyaman dan aman."
        />
        <meta
          name="keywords"
          content="grand wisata bekasi, grand wisata property, grand wisata, grand wisata tambun, cherry ville, garden hous, torena, torena+, o8 perfect home, o8, westfield, vermella, grand business park, water terrace"
        />
        <meta name="distribution" content="web" />
        <link
          rel="canonical"
          href="https://www.grandwisataproperty.com/products"
        />
        <meta
          property="og:title"
          content="Produk | Grand Wisata Bekasi | Kota Mandiri Terintegrasi dan Terencana di Timur Jakarta"
        />
        <meta
          property="og:description"
          content="Grand Wisata hadir dengan menawarkan berbagai produk hunian/ruang usaha yang inovatif dan mengusung konsep lingkungan sehat, memberikan rasa nyaman dan aman."
        />
      </Helmet>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <PageHeader bg={banner}>Produk</PageHeader>
        <section className="container">
          <div className="row">
            <div className="col-12">
              <p>{desc}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Cards datas={items} />
            </div>
          </div>
        </section>
        <PageFooter />
      </motion.div>
    </Layout>
  )
}

export const query = graphql`
  {
    allItemsJson(sort: { fields: sorting, order: DESC }) {
      nodes {
        img {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        link
        product
        type
      }
    }
    productsJson {
      banner {
        childImageSharp {
          fluid(maxWidth: 6000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      desc
    }
  }
`

export default products
